import React from 'react'
import {Row, Col, Input, Button,Select} from 'antd'
const {Option} = Select

class SearchGroup extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            viewName:'',
            tag:'',
            type:''
        }
    }

    onChangeResultDate(e) {
        this.setState({
            viewName:e.target.value
        })
        this.props.resultDateSearch(e.target.value)
    }
    optionsChangeSex(value){
        this.setState({
            tag:value
        })
        this.props.optionsChangeSex(value)
    }
    optionsChangeType(value){
        this.setState({
            type:value
        })
        this.props.optionsChangeType(value)
    }
    searchList() {
        this.props.searchList()
    }
    reset(){
        this.setState({
            viewName:'',
            tag:'',
            type:''
        })
        this.props.resetChange()
    }

    render() {
        const tagData=this.props.tagData
        const typeData=this.props.typeData
        return (
            <Row style={{padding: '10px 20px'}}>
                <Col offset={3} xl={5} lg={5} md={5}>
                    <label style={{marginRight: '5px'}}>路线分类：</label>
                    <Select
                        style={{width:'50%'}}
                        size={'small'}
                        value={this.state.type} onChange={this.optionsChangeType.bind(this)}
                    >
                        {
                            typeData.map((item,index)=>{
                                return <Option key={index} value={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                  
                </Col>
                <Col  xl={5} lg={5} md={5}>
                    <label style={{marginRight: '5px'}}>游览分类：</label>
                    <Select
                        style={{width:'50%'}}
                        size={'small'}
                        value={this.state.tag} onChange={this.optionsChangeSex.bind(this)}
                    >
                        {
                            tagData.map((item,index)=>{
                                return <Option key={index} value={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                  
                </Col>
                <Col xl={6} lg={6} md={6}>
                    <label style={{marginRight: '5px'}}>名称：</label>
                    <Input style={{width:'50%'}} size={'small'} value={this.state.viewName}  onChange={this.onChangeResultDate.bind(this)}/>
                </Col>
                <Col xl={5} lg={5} md={5}>
                    <Button type="primary" onClick={this.searchList.bind(this)} style={{marginRight:'10px'}}>查询</Button>
                    <Button onClick={this.reset.bind(this)}>重置</Button>
                </Col>
            </Row>
        )
    }
}

export default SearchGroup