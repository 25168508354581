import React, { Component } from 'react';
import axios from '../../axios'
import {beforeRouterEach} from '../../common/index'
import TableGroup from '../../base/table/index'
import Title from '../../base/title/index'
import { message,Modal,Icon,Divider} from 'antd';
const { confirm } = Modal;

class Index extends Component {
    constructor(props,context){
        super(props,context);
        this.state={
            titleMsg: {
                titleName: '自驾路线推荐',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title:'排序',
                        dataIndex:'column',
                        render: (text, record,index) => (
                            <span>
                                    <span className='tableBtn' onClick={(event) => {
                                            this.seqUp(record,index)
                                            event.stopPropagation()
                                        }}><Icon type="arrow-up" /></span><Divider type="vertical"/>
                                         <span className='tableBtn' onClick={(event) => {
                                            this.seqDown(record,index)
                                            event.stopPropagation()
                                        }}><Icon type="arrow-down" /></span>
                        </span>
                        ),
                    }, 
                    {
                        title:'路线名称',
                        dataIndex:'name',
                    },  
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                            <span>
                                    <span className='tableBtn' onClick={(event) => {
                                            this.cancel(record)
                                            event.stopPropagation()
                                        }}>取消</span>
                        </span>
                        ),
                    }
                ],
                data: [],
                isSelection: false
            },
            tableParams:{
                rows:10,
                page:1,
                hot:1,
                type:'SELF_DRIVE'
            },
            selected:[],
            tabData:[]
        }
    }
    
    seqDown(record,index){
        let length=index+1;
        if(length<this.state.table.data.length){
            let sort1=this.state.table.data[length].sort;
            let sort2=record.sort;
            record.sort=sort1;
            let downRow=this.state.table.data[length];
            downRow.sort=sort2;
            axios.posts('/api/v1/route/modify',record)
              .then((res)=>{
                  if(res.status===200){
                    axios.posts('/api/v1/route/modify',downRow)
                    .then((res)=>{
                        if(res.status===200){
                            message.success('操作成功！')
                            this.getDataList()
                        }else{
                            message.error(res.message)
                        }
                    })
                  }else{
                      message.error(res.message)
                  }
              })
        }
        
    }
    seqUp(record,index){
        if(index>0){
            let sort1=this.state.table.data[index-1].sort;
            let sort2=record.sort;
            record.sort=sort1;
            let upRow=this.state.table.data[index-1];
            upRow.sort=sort2;
            axios.posts('/api/v1/route/modify',record)
              .then((res)=>{
                  if(res.status===200){
                    axios.posts('/api/v1/route/modify',upRow)
                    .then((res)=>{
                        if(res.status===200){
                            message.success('操作成功！')
                            this.getDataList()
                        }else{
                            message.error(res.message)
                        }
                    })
                  }else{
                      message.error(res.message)
                  }
              })
        }
    }
    cancel(record){
        let _this=this;
        confirm({
            title: '是否取消当前数据为推荐路线?',
            content: '',
            onOk() {
                record.hot=0;
              axios.posts('/api/v1/route/modify',record)
                .then((res)=>{
                    if(res.status===200){
                        message.success('操作成功！')
                        _this.getDataList()
                    }else{
                        message.error(res.message)
                    }
                })
            },
            onCancel() {
              console.log('Cancel');
            },
        });
    }
  
    getDataList(){
        axios.gets('/api/v1/route/find',this.state.tableParams)
            .then((res)=>{
                if(res.status===200){
                    let data = Object.assign({}, this.state.table, {
                        data: res.data.records,
                        totalCount: res.data.total,
                    });
                    this.setState({
                        table: data
                    })
                }else{
                    message.error(res.message)
                }
            })
    }
   
    selectedChange(val){
        this.setState({
            selected:val
        })
    }
    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });

    }
    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page ,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }
   
    componentDidMount(){
        beforeRouterEach(this)
        this.getDataList()
    }

    render() {
        return (
            <div className="tagManage">
                <Title titleMsg={this.state.titleMsg}/> 
                <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table}/>
            </div>
        );
    }
}

export default Index;