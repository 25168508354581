import React from 'react'
import {Row, Col,Button,Modal,Input,Tree } from 'antd'

const { TreeNode } = Tree;

class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            name:''
        }
    }
    createAccount= (e) => {
        e.preventDefault();
        this.props.changeVisible(false,'submit');
    }
    cancelCreateAccount(){
        this.props.changeVisible(false,'');
    }
    onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
    };
    
    onCheck = (checkedKeys, info) => {
        this.props.changePermissionArrVisible(checkedKeys);
    };
    onChangeResultDate(e) {
        this.props.changePermissionNameVisible(e.target.value);
    }
    render() {
        const data=this.props.data
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    width={500}
                    visible={this.props.visible}
                    title={"编辑管理权限"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Row>
                        <Col span={24}>
                            <label>权限名称：</label>
                            <Input value={data.name} disabled  onChange={this.onChangeResultDate.bind(this)} style={{width:'70%'}} size={'small'}/>
                        </Col>
                        <Col span={24}>
                        <Tree
                            checkable
                            onSelect={this.onSelect.bind(this)}
                            onCheck={this.onCheck.bind(this)}
                            defaultCheckedKeys={data.content}
                        >
                            <TreeNode title="用户管理" key="用户管理"/>
                            <TreeNode title="首页管理" key="首页管理" >
                                <TreeNode title="展示图设置" key="展示图设置"/>
                                <TreeNode title="精品推荐" key="精品推荐" />
                                <TreeNode title="经典路线推荐" key="经典路线推荐" />
                                <TreeNode title="自驾路线推荐" key="自驾路线推荐" />
                            </TreeNode>
                            <TreeNode title="景点管理" key="景点管理"/>
                            <TreeNode title="酒店管理" key="酒店管理"/>
                            <TreeNode title="美食管理" key="美食管理"/>
                            <TreeNode title="购物管理" key="购物管理"/>
                            <TreeNode title="玩乐管理" key="玩乐管理"/>
                            <TreeNode title="路线管理" key="路线管理"/>
                            <TreeNode title="指南管理" key="指南管理"/>
                            <TreeNode title="标签分类管理" key="标签分类管理"/>
                            <TreeNode title="求助管理" key="求助管理"/>
                            <TreeNode title="管理员管理" key="管理员管理"/>
                            <TreeNode title="后台登录展示图" key="后台登录展示图"/>
                            <TreeNode title="系统安全" key="系统安全"/>
                        </Tree>
                        </Col>
                    </Row>
                </Modal>    
        )
    }
}

export default CreateModal;